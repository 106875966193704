import React from "react"

import Layout from "../../components/layout"
import Band, { BandColors } from "../../components/band"
import SEO from "../../components/seo"
import styles from "./about.module.css"
import EricDodson from "../../images/EricDodson.jpg"
import BillDodson from "../../images/BillDodson.jpg"
import linkedin from "../../images/linkedin.png"

const AboutPage = () => (
  <Layout>
    <SEO title="About" keywords={[`tigerface`, `systems`, `salesforce`, `about us`]} />

    <Band>
      <h1>About Us</h1>
      <p>
        Tigerface Systems is a custom software development company based in the suburbs of Maryland, USA. 
        We specialize in custom Salesforce solutions that are marketed and sold on the Salesforce AppExchange. 
        We are a private, family-owned and operated company.  We do all work in-house to ensure the utmost highest 
        quality is engineered from the beginning and maintained throughout our product’s lifecycle.
      </p>
    </Band>
    <Band color={BandColors.gray}>
      <h2>Our Mission</h2>
      <p>
        Tigerface Systems provides production-ready Salesforce solutions that offer best in-class 
        quality and robustness at affordable prices. With a pay-as-you-go and no up-front cost model, 
        customers can utilize our solutions to deliver enhanced user experiences at reduced cost.
      </p>
    </Band>
    <Band>
      <h2>Our Team</h2>
      <section className={styles.employeesWrapper}>
        <div className={styles.employee}>
          <div className={styles.employeeTop}>
            <div className={styles.employeeImg}>
              <img src={BillDodson} alt="Bill Dodson" />
            </div>
            <div>
              <h3>Bill Dodson</h3>
              <div className={styles.role}>CEO and Founder</div>
              <a href="https://www.linkedin.com/in/william-dodson-29185724" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="View Linkedin" className={styles.linkedin} />
              </a>
            </div>
          </div>
          <p>
            William Dodson is the CEO and Founder of Tigerface Systems, LLC. 
            He is responsible for the company’s vision, product strategy, hands-on custom software development, 
            and product support.
          </p>
          <p>
            William has spent his entire career, spanning decades, in the custom software development industry. 
            He has delivered numerous complex systems in industries such as Health Care, Finance, Defense, and 
            Transportation.  He has held various positions over the years such as software developer, technical lead, 
            and senior manager on multi-million-dollar projects.
          </p>
          <p>
            William currently holds 15 Salesforce certifications, including certified Salesforce Application 
            Architect, certified Salesforce Systems Architect, and Salesforce Platform II. 
            He also has BS and MS degrees in Computer Science.
          </p>
        </div>
        <div className={styles.employee}>
          <div className={styles.employeeTop}>
            <div className={styles.employeeImg}>
              <img src={EricDodson} alt="Eric Dodson" />
            </div>
            <div>
              <h3>Eric Dodson</h3>
              <div className={styles.role}>CTO and Co-Founder</div>
              <a href="https://www.linkedin.com/in/eric-dodson-2a0a98aa" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="View Linkedin" className={styles.linkedin} />
              </a>
            </div>
          </div>
          <p>
            Eric Dodson is the CTO and Co-Founder of Tigerface Systems, LLC. 
            Eric is responsible for product strategy and design, hands-on custom software development, 
            product lifecycle management, and quality assurance processes and procedures.
          </p>
          <p>
            Eric has extensive experience in developing enterprise grade cloud-based web applications. 
            His experience includes Website Administrator at BMW of Towson and Lead Web Application Developer 
            for the largest health insurance provider in the mid-Atlantic region. 
            He has deep and extensive knowledge on various technologies such as Lightning Web Components, 
            Flutter, Angular, React, Bootstrap, HTML5, CSS3, NodeJS, and object-oriented application design and 
            development methodologies.
          </p>
          <p>
            Eric has several industry certifications such as certified Salesforce App Builder, 
            certified AWS Solution Architect, and certified AWS Developer. 
            Eric also has a BS degree in computer science.  
          </p>
        </div>
      </section>
    </Band>
  </Layout>
);

export default AboutPage;