import React from "react"
import PropTypes from "prop-types"

import cssVar from "../shared/variables.module.css"

export const BandColors = {
  white: { bg: '#fff', text: 'inherit' },
  yellow: { bg: '#f4c85f', text: 'inherit' },
  orange: { bg: cssVar.primaryColor, text: '#fff' },
  gray: { bg: '#f2f2f2', text: 'inherit' }
};

export const BandSizes = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  xSmall: 'x-small'
}

const getPadding = (size) => {
  if (size === BandSizes.large)
    return '4rem 0';
  else if (size === BandSizes.medium)
    return '3rem 0';
  else if (size === BandSizes.small)
    return '2rem 0';
  else if (size === BandSizes.xSmall)
    return '1rem 0';
};

const Band = ({ children, color = BandColors.white, size = BandSizes.medium, className }) => (
  <div className={className} style={{ padding: getPadding(size), background: color.bg, color: color.text }}>
    <div style={{ margin: 'auto', maxWidth: 1200, padding: `0 1rem`, }}>
      {children}
    </div>
  </div>
);

Band.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Band;